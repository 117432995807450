<template>
	<div class="body">
		<!-- <div v-if="!Homeflage" class="homeheader">
			<div class="header-left" style="cursor: pointer;" @click="$router.push('/')">
				<img style="width: 50%;height: 70%;" src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/RHLogo.jpg">
			</div>
			<div class="header-center">
				<div class="zt">
					<router-link :to='("/store")' class="tit" style="width: 30%; color: #000;text-decoration:none;" tag="a" target="_blank" @mouseover="mouseLeave">
						商城
					</router-link>
					<div class="tit" @mouseover="mouseOver">
						产品
					</div>
					<div class="tit" @click="$router.push('/case')" @mouseover="mouseLeave">
						案例
					</div>
					<div class="tit" @click="$router.push('/downAPP')" @mouseover="mouseLeave">
						下载
					</div>
				</div>
				<transition name="plus-icon">
					<div v-if="shoplist" id="list">
						<div id="listcenbox" @mouseleave="mouseLeave">
							<div id="B1">
								<p>仓储进销存</p>
								<div class="listbox">
									<div @click="$router.push('/productpresentation'),shoplist = false">
										<icon-svg class='iconclas' iconClass='shangquan'></icon-svg>
										<span class="listtext">
											商贸型进销存管理
										</span>
									</div>
									<div @click="$router.push('/clothing'),shoplist = false">
										<icon-svg class='iconclas' iconClass='fuzhuang-duanqun'></icon-svg>
										<span class="listtext">
											服装布料进销存
										</span>
									</div>
									<div @click="$router.push('/Metalbuildingmaterials'),shoplist = false">
										<icon-svg class='iconclas' iconClass='wujingongju'></icon-svg>
										<span class="listtext">
											五金建筑进销存
										</span>
									</div>
									<div @click="$router.push('/homeappliances'),shoplist = false">
										<icon-svg class='iconclas' iconClass='jiadianxingye'></icon-svg>
										<span class="listtext">
											家电零售进销存
										</span>
									</div>
								</div>
							</div>
							<div id="B1">
								<p>OA办公</p>
								<div class="listbox">
									<div @click="OAfun(1)">
										<icon-svg class='iconclas' iconClass='jianzhu'></icon-svg>
										<span class="listtext">
											建筑工程行业
										</span>
									</div>
									<div @click="OAfun(2)">
										<icon-svg class='iconclas' iconClass='caidanliaozhuangxiu'></icon-svg>
										<span class="listtext">
											房地产装饰行业
										</span>
									</div>
									<div @click="OAfun(3)">
										<icon-svg class='iconclas' iconClass='fuwu1'></icon-svg>
										<span class="listtext">
											现代服务业
										</span>
									</div>
									<div @click="OAfun(4)">
										<icon-svg class='iconclas' iconClass='shengchanzhizao'></icon-svg>
										<span class="listtext">
											生产制造行业
										</span>
									</div>
									<div @click="OAfun(5)">
										<icon-svg class='iconclas' iconClass='lvshijindiao'></icon-svg>
										<span class="listtext">
											律所办公行业
										</span>
									</div>
								</div>
							</div>
							<div id="B1">
								<p>门店管理系统</p>
								<div class="listbox">
									<div @click="$router.push('/beautyindustry'),shoplist = false">
										<icon-svg class='iconclas' iconClass='40'></icon-svg>
										<span class="listtext">
											美容美发行业
										</span>
									</div>
									<div @click="$router.push('/amusementpark'),shoplist = false">
										<icon-svg class='iconclas' iconClass='jianshen-'></icon-svg>
										<span class="listtext">
											游乐场健身行业
										</span>
									</div>
									<div @click="$router.push('/chessandcard'),shoplist = false">
										<icon-svg class='iconclas' iconClass='a-qipaichangguan'></icon-svg>
										<span class="listtext">
											棋牌会所行业
										</span>
									</div>
									<div @click="$router.push('/Theautoparts'),shoplist = false">
										<icon-svg class='iconclas' iconClass='qicheweixiu'></icon-svg>
										<span class="listtext">
											汽修汽配行业
										</span>
									</div>
									<div @click="$router.push('/Businesssuperretail'),shoplist = false">
										<icon-svg class='iconclas' iconClass='dianpu1'></icon-svg>
										<span class="listtext">
											商超零售行业
										</span>
									</div>
								</div>
							</div>
							<div id="B1">
								<p>智能报修系统</p>
								<div class="listbox" style="">
									<div @click="YLfun(1)">
										<icon-svg class='iconclas' iconClass='zu'></icon-svg>
										<span class="listtext">
											医疗设备行业
										</span>
									</div>
									<div @click="YLfun(2)">
										<icon-svg class='iconclas' iconClass='yiqiyibiao'></icon-svg>
										<span class="listtext">
											仪器仪表行业
										</span>
									</div>
									<div @click="YLfun(3)">
										<icon-svg class='iconclas' iconClass='xingzhuang'></icon-svg>
										<span class="listtext">
											家用电器行业
										</span>
									</div>
									<div @click="YLfun(4)">
										<icon-svg class='iconclas' iconClass='jiankong'></icon-svg>
										<span class="listtext">
											安防监控行业
										</span>
									</div>
									<div @click="YLfun(5)">
										<icon-svg class='iconclas' iconClass='kongtiao1'></icon-svg>
										<span class="listtext">
											空调设备行业
										</span>
									</div>
									<div @click="YLfun(6)">
										<icon-svg class='iconclas' iconClass='jiancai'></icon-svg>
										<span class="listtext">
											家居建材行业
										</span>
									</div>
									<div @click="YLfun(7)">
										<icon-svg class='iconclas' iconClass='jianshen'></icon-svg>
										<span class="listtext">
											健身设备行业
										</span>
									</div>
									<div @click="YLfun(8)">
										<icon-svg class='iconclas' iconClass='huanbao'></icon-svg>
										<span class="listtext">
											环保设备行业
										</span>
									</div>
								
								</div>
							</div>
							<div id="B1">
								<p>ERP系统</p>
								<div class="listbox" style="border: none">
									<div @click="jumpERP(1)">
										<icon-svg class='iconclas' iconClass='wujin'></icon-svg>
										<span class="listtext">
											五金建筑行业
										</span>
									</div>
									<div @click="jumpERP(2)">
										<icon-svg class='iconclas' iconClass='zhuangxiu1'></icon-svg>
										<span class="listtext">
											装修装饰行业
										</span>
									</div>
									<div @click="jumpERP(2)">
										<icon-svg class='iconclas' iconClass='huanbao1'></icon-svg>
										<span class="listtext">
											环保水利行业
										</span>
									</div>
									<div @click="jumpERP(2)">
										<icon-svg class='iconclas' iconClass='anfangfuwu'></icon-svg>
										<span class="listtext">
											安防弱电行业
										</span>
									</div>
									<div @click="jumpERP(2)">
										<icon-svg class='iconclas' iconClass='jianzhu'></icon-svg>
										<span class="listtext">
											幕墙建筑行业
										</span>
									</div>
									<div @click="jumpERP(2)">
										<icon-svg class='iconclas' iconClass='shengchanzhizao'></icon-svg>
										<span class="listtext">
											生产制造行业
										</span>
									</div>
								</div>
							</div>
						
						</div>
					</div>
				</transition>
			
			
			</div>
			<div class="header-right">
				<button class="login" @click="logfun">登录</button>
				<button class="rebtn" @click="zcfun">免费注册</button>
				<button class="MFzh" @click="centerDialogVisible=true">试用账户</button>
			</div>
		</div>
		 -->
		<!-- phone头部 -->
		<!-- <div v-if="Homeflage" class="phone-homeheader">
			<div class="phone-hearder-left" @click="$router.push('/'),tttxt='分享代理'">
				<img style="width: 50%;height: 40%;" src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/RHLogo.jpg">
			</div>
			<div class="phone-hearder-right">
				<i class="el-icon-s-unfold" @click="drawer = true"></i>
			</div>
		</div> -->
		<!-- <el-drawer :visible.sync="drawer" :with-header="false" size="90%">
			<p class="dratit"><img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/RHLogo.jpg"/> 融恒
			</p>
			<div id="drawer_header">
				<div id="drawer_header_box" @click="$router.push('/PhoneStore'),drawer=false,tttxt='分享商品'">
					商 城
				</div>
				<div id="drawer_header_box" @click="$router.push('/downAPP'),drawer=false,tttxt='分享代理'">
					下载中心
				</div>
				<div id="drawer_header_box" @click="Abfun('on'),drawer=false,tttxt='分享代理'">
					关 于
				</div>
				<div id="drawer_header_box" @click="$router.push('/case'),drawer=false,tttxt='分享代理'">
					案 例
				</div>
			</div>
			<div id="drawer_main">
				<p>仓储进销存</p>
				<div id="drawer_main_box">
					<div id="drawer_main_box_flex" @click="smfun">
						商贸型进销存管理
					</div>
					<div id="drawer_main_box_flex" @click="Fzfun">
						服装布料进销存
					</div>
					<div id="drawer_main_box_flex" @click="Jdfun">
						五金建筑进销存
					</div>
					<div id="drawer_main_box_flex">
						家电零售进销存
					</div>
				</div>
			</div>
			<div id="drawer_main">
				<p>OA办公</p>
				<div id="drawer_main_box">
					<div id="drawer_main_box_flex" @click="JZfun(1)">
						建筑工程行业
					</div>
					<div id="drawer_main_box_flex" @click="JZfun(2)">
						房地产装饰行业
					</div>
					<div id="drawer_main_box_flex" @click="JZfun(3)">
						现代服务业
					</div>
					<div id="drawer_main_box_flex" @click="JZfun(4)">
						生产制造行业
					</div>
					
					<div id="drawer_main_box_flex" @click="OAfun(5)">
						律所办公行业
					</div>
				</div>
			</div>
			<div id="drawer_main">
				<p>门店管理系统</p>
				<div id="drawer_main_box">
					<div id="drawer_main_box_flex" @click="Myfun">
						美容美发行业
					</div>
					<div id="drawer_main_box_flex" @click="YLCfun">
						游乐场健身行业
					</div>
					<div id="drawer_main_box_flex" @click="qpfun">
						棋牌会所行业
					</div>
					<div id="drawer_main_box_flex" @click="qxfun">
						汽修汽配行业
					</div>
					<div id="drawer_main_box_flex" @click="SCfun">
						商超零售行业
					</div>
				</div>
			</div>
			<div id="drawer_main">
				<p>智能报修系统</p>
				<div id="drawer_main_box">
					<div id="drawer_main_box_flex" @click="YLfun(1)">
						医疗设备行业
					</div>
					<div id="drawer_main_box_flex" @click="YLfun(2)">
						仪器仪表行业
					</div>
					<div id="drawer_main_box_flex" @click="YLfun(3)">
						家用电器行业
					</div>
					<div id="drawer_main_box_flex" @click="YLfun(4)">
						安防监控行业
					</div>
					<div id="drawer_main_box_flex" @click="YLfun(5)">
						空调设备行业
					</div>
					<div id="drawer_main_box_flex" @click="YLfun(6)">
						家居建材行业
					</div>
					<div id="drawer_main_box_flex" @click="YLfun(7)">
						健身设备行业
					</div>
					<div id="drawer_main_box_flex" @click="YLfun(8)">
						环保设备行业
					</div>
				</div>
			</div>
			<div id="drawer_main">
				<p>ERP系统</p>
				<div id="drawer_main_box">
					<div id="drawer_main_box_flex" @click="jumpERP(1)">
						五金建筑行业
					</div>
					<div id="drawer_main_box_flex" @click="jumpERP(2)">
						装修装饰行业
					</div>
					<div id="drawer_main_box_flex" @click="jumpERP(3)">
						环保水利行业
					</div>
					<div id="drawer_main_box_flex" @click="jumpERP(4)">
						安防弱电行业
					</div>
					<div id="drawer_main_box_flex" @click="jumpERP(5)">
						幕墙建筑行业
					</div>
					<div id="drawer_main_box_flex" @click="jumpERP(6)">
						生产制造行业
					</div>
				</div>
			</div>
		</el-drawer>
		<el-dialog :visible.sync="centerDialogVisible" center title="热门行业公用账户" width="40%">
			<el-table :data="tableData" style="width: 100%">
				<el-table-column label="行业名称" prop="HY" width="180">
				</el-table-column>
				<el-table-column label="账户名称" prop="Name" width="180">
				</el-table-column>
				<el-table-column label="账户" prop="ZH" width="180">
				</el-table-column>
				<el-table-column label="密码" prop="pass">
				</el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
		<div id="flbox" @click="$router.push('/cooperation')">
			<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/hz.png">
			<p>合作代理</p>
		</div>
		<div id="flboxleft" @click="TEX">
			<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/hz.png">
			<p>{{ tttxt }}</p>
		</div> -->
		<!-- <el-dialog :visible.sync="YQcodeflage" center title="请输入你的邀请码" width="30%">
			<el-input v-model="inputcode" clearable placeholder="请输入邀请码">
			</el-input>
			<span slot="footer" class="dialog-footer">
				<el-button @click="YQcodeflage = false">取 消</el-button>
				<el-button type="primary" @click="YQcodefun">确 定</el-button>
			</span>
		</el-dialog> -->
		<!-- <el-dialog :visible.sync="YQcodeflages" center title="请输入你的邀请码" width="80%">
			<el-input v-model="inputcode" clearable placeholder="请输入邀请码">
			</el-input>
			<span slot="footer" class="dialog-footer">
				<el-button @click="YQcodeflages = false">取 消</el-button>
				<el-button type="primary" @click="YQcodefun">确 定</el-button>
			</span>
		</el-dialog> -->
		
		<router-view></router-view>
	</div>
</template>

<script>

export default {
	data() {
		return {
			tttxt: "分享代理",
			activeIndex: '1',
			screenWidth: document.body.clientWidth,
			timer: false,
			Homeflage: false,
			drawer: false, //手机导航栏
			shoplist: false,
			aboudusflage: false,
			centerDialogVisible: false,
			YQcodeflage: false,
			corul: "",
			YQcodeflages: false,
			inputcode: "",
			tableData: [{
				HY: '进销存管理系统',
				Name: 'JXCDemo',
				ZH: 'admin',
				pass: '123'
			}, {
				HY: 'OA办公',
				Name: 'OADemo',
				ZH: 'admin',
				pass: '123'
			}, {
				HY: '美容美发行业',
				Name: 'MYDemo',
				ZH: 'admin',
				pass: '123'
			}, {
				HY: '汽修行业',
				Name: 'QXDemo',
				ZH: 'admin',
				pass: '123'
			}, {
				HY: '商超零售行业',
				Name: 'SCDemo',
				ZH: 'admin',
				pass: '123'
			}, {
				HY: '智能报修行业',
				Name: 'BXDemo',
				ZH: 'admin',
				pass: '123'
			}, {
				HY: '工程ERP',
				Name: 'ERPtest',
				ZH: 'admin',
				pass: '123'
			}]
		}
	},
	mounted() {
		if (this.$route.path == '/PhoneStore') {
			this.tttxt = '分享商品'
		} else {
			this.tttxt = '分享代理'
		}
		const that = this
		if (window.innerWidth < 720) {
			this.Homeflage = true
		} else {
			this.Homeflage = false
		}
		window.onresize = () => {
			return (() => {
				window.screenWidth = document.body.clientWidth
				that.screenWidth = window.screenWidth
			})()
			
		}
	},
	methods: {
		jumpERP() {
			this.$router.push('/ERP/introduce')
		},
		handleSelect(key, keyPath) {
			console.log(key, keyPath);
		},
		TEX() {
			if (window.innerWidth < 720) {
				this.YQcodeflages = true
			} else {
				this.YQcodeflage = true
			}
		},
		YQcodefun() { //生成邀请码链接
			if (window.innerWidth < 720) {
				console.log(this.$route.path)
				if (this.$route.path == '/PhoneStore') {
					let dataurl = 'http://www.rhdgj.com/#/PhoneStore?code=' + this.inputcode
					// let dataurl = 'http://localhost:8080/#/PhoneStore?code=' + this.inputcode
					this.corul = dataurl
					// console.log(dataurl)
					var input = document.createElement("input"); // js创建一个input输入框
					input.value = dataurl; // 将需要复制的文本赋值到创建的input输入框中
					document.body.appendChild(input); // 将输入框暂时创建到实例里面
					input.select(); // 选中输入框中的内容
					document.execCommand("Copy"); // 执行复制操作
					document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
					this.$message({
						type: 'success',
						message: '复制成功'
					})
					this.YQcodeflage = false
					this.YQcodeflages = false
					// console.log(clipboard)
				} else {
					let dataurl = 'http://www.rhdgj.com/#/cooperation?code=' + this.inputcode
					// let dataurl = 'http://localhost:8080/#/cooperation?code=' + this.inputcode
					this.corul = dataurl
					// console.log(dataurl)
					var input = document.createElement("input"); // js创建一个input输入框
					input.value = dataurl; // 将需要复制的文本赋值到创建的input输入框中
					document.body.appendChild(input); // 将输入框暂时创建到实例里面
					input.select(); // 选中输入框中的内容
					document.execCommand("Copy"); // 执行复制操作
					document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
					this.$message({
						type: 'success',
						message: '复制成功'
					})
					this.YQcodeflage = false
					this.YQcodeflages = false
					// console.log(clipboard)
				}
				
			} else {
				let dataurl = 'http://www.rhdgj.com/#/cooperation?code=' + this.inputcode
				// let dataurl = 'http://localhost:8080/#/cooperation?code=' + this.inputcode
				this.corul = dataurl
				// console.log(dataurl)
				var input = document.createElement("input"); // js创建一个input输入框
				input.value = dataurl; // 将需要复制的文本赋值到创建的input输入框中
				document.body.appendChild(input); // 将输入框暂时创建到实例里面
				input.select(); // 选中输入框中的内容
				document.execCommand("Copy"); // 执行复制操作
				document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
				this.$message({
					type: 'success',
					message: '复制成功'
				})
				this.YQcodeflage = false
				this.YQcodeflages = false
				// console.log(clipboard)
			}
		},
		// 鼠标移入产品显示列表
		mouseOver() {
			this.shoplist = true
			
		},
		mouseLeave() {
			this.shoplist = false
			this.aboudusflage = false
		},
		mouseLeaves() {
			this.shoplist = false
			this.aboudusflage = true
		},
		mouseLeavess() {
			this.aboudusflage = false
		},
		zcfun() { //注册
			window.open('http://rhdgjzc.ronghengzn.com/', '_blank');
		},
		logfun() { //登录
			window.open('http://rhdgj.ronghengzn.com/', '_blank');
		},
		smfun() { //商贸进销存跳转
			this.$router.push('/productpresentation')
			this.drawer = false
			this.tttxt = '分享代理'
		},
		Fzfun() { //服装布料跳转
			this.$router.push('/clothing')
			this.drawer = false
			this.tttxt = '分享代理'
		},
		Wjfun() { //五金跳转
			this.$router.push('/Metalbuildingmaterials')
			this.drawer = false
			this.tttxt = '分享代理'
		},
		Jdfun() { //家电跳转
			this.$router.push('/homeappliances')
			this.drawer = false
			this.tttxt = '分享代理'
		},
		JZfun(i) {
			this.$router.push('/OA')
			this.$store.commit('OAidfun', i)
			this.drawer = false
			this.tttxt = '分享代理'
		},
		OAfun(i) { //OA跳转
			if (i === 5) {
				this.$router.push('/LawFirm/LawFirm')
				this.$store.commit('OAidfun', i)
				this.drawer = false
				this.tttxt = '分享代理'
				
			} else {
				this.$router.push('/OA')
				this.shoplist = false
				this.$store.commit('OAidfun', i)
				this.tttxt = '分享代理'
			}
			
			
		},
		Abfun(val) { //关于我们
			this.$router.push('/aboutUs')
			this.aboudusflage = false
			this.$store.commit('abtabfun', val)
			this.tttxt = '分享代理'
		},
		Myfun() { //美业跳转
			this.drawer = false
			this.$router.push('/beautyindustry')
			this.tttxt = '分享代理'
			
		},
		YLCfun() { //游乐场跳转
			this.drawer = false
			this.$router.push('/amusementpark')
			this.tttxt = '分享代理'
			
		},
		qpfun() {
			this.drawer = false
			this.$router.push('/chessandcard')
			this.tttxt = '分享代理'
		},
		qxfun() {
			this.drawer = false
			this.$router.push('/Theautoparts')
			this.tttxt = '分享代理'
		},
		SCfun() {
			this.drawer = false
			this.$router.push('/Businesssuperretail')
			this.tttxt = '分享代理'
		},
		YLfun(i) { //医疗行业
			console.log(i)
			this.drawer = false
			this.$router.push('/medicaltreatment')
			this.tttxt = '分享代理'
		}
	},
	watch: {
		screenWidth(val) {
			// 为了避免频繁触发resize函数导致页面卡顿，使用定时器
			if (!this.timer) {
				// 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
				this.screenWidth = val
				this.timer = true
				let that = this
				console.log(that.screenWidth)
				that.timer = false
				if (that.screenWidth < 720) {
					this.Homeflage = true
				} else {
					this.Homeflage = false
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
.homeheader {
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #f5f5f5;
	position: sticky;
	position: -webkit-sticky; // 兼容 -webkit 内核的浏览器
	top: 0px;
	background-color: #FFFFFF;
	z-index: 999;
	
	.header-left {
		width: 20%;
		height: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		
		img {
			height: 100%;
		}
	}
	
	.header-center {
		width: 60%;
		height: 100%;
		
		.zt {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			text-align: center;
			
			.tit {
				width: 20%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				font-size: 18px;
				transition: all .5s;
			}
			
			.tit:hover {
				color: #fd3f31;
				border-bottom: 3px solid #fd3f31;
			}
		}
	}
	
	.header-right {
		width: 20%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		
		.login {
			background-color: rgba(0, 0, 0, 0);
			border: none;
			width: 80px;
			height: 40px;
			border: 1px solid rgba(253, 63, 49, 1);
			color: rgba(253, 63, 49, 1);
			border-radius: 4px;
		}
		
		.rebtn {
			background-color: rgba(0, 0, 0, 0);
			border: none;
			width: 100px;
			height: 40px;
			background-color: rgba(253, 63, 49, 1);
			border: 1px solid rgba(253, 63, 49, 1);
			color: #fff;
			margin-left: 2%;
		}
		
		.MFzh {
			background-color: rgba(0, 0, 0, 0);
			border: none;
			width: 100px;
			height: 40px;
			background: linear-gradient(to right bottom, #FD3F31, #fd766a);
			// background-color: rgba(253, 63, 49, 1);
			color: #fff;
			margin-left: 2%;
		}
	}
}

// 手机头部样式
.phone-homeheader {
	width: 100%;
	height: 8vh;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #f5f5f5;
	position: sticky;
	position: -webkit-sticky; // 兼容 -webkit 内核的浏览器
	top: 0px;
	background-color: #FFFFFF;
	z-index: 999;
	
	.phone-hearder-left {
		width: 20%;
		height: 100%;
		
		img {
			height: 100%;
		}
	}
	
	.phone-hearder-right {
		color: #fd3f31;
		font-size: 40px;
	}
}

.phtit {
	// height: 33px;
	margin-left: 5%;
	margin-bottom: 10px;
	
	ul {
		li {
			margin-bottom: 10px;
		}
	}
}

// PC产品信息列表
#list {
	width: 100%;
	height: 550px;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.2);
	top: 101px;
	left: 0;
	z-index: 999;
	
	#listcenbox {
		width: 70%;
		height: 100%;
		border-radius: 5px;
		margin-left: 15%;
		background-color: #fff;
		display: flex;
		
		#B1 {
			width: 25%;
			padding-top: 3%;
			
			.listbox {
				border-right: 1px solid #d8d8d8;
				height: 80%;
				text-align: left;
				margin-left: 5%;
				width: 90%;
				
				div {
					height: 55px;
					line-height: 55px;
					cursor: pointer;
					color: #606068;
					
					.listtext:hover {
						color: #fd3f31;
					}
				}
			}
			
			p {
				color: #989898;
			}
		}
	}
}

/deep/ .el-drawer {
	overflow: auto;
}

.iconclas {
	color: #fd3f31;
	font-size: 30px;
}

@media screen and(min-width: 720px) {
	#aboutuslist {
		width: 260px;
		height: 400px;
		position: absolute;
		background-color: rgba(255, 255, 255);
		top: 100px;
		left: 55%;
		z-index: 999;
		border-radius: 5px;
		
		#aboutuslist_box {
			width: 100%;
			text-align: center;
			height: 50px;
			line-height: 50px;
			cursor: pointer;
			
			p:hover {
				color: #fd3f31;
			}
		}
	}
	
	.plus-icon-enter-active {
		transition: opacity .5s;
	}
	
	.plus-icon-enter {
		opacity: 0;
	}
	
	.plus-icon-leave-active {
		transition: opacity .5s;
	}
	
	.plus-icon-leave-to {
		opacity: 0;
	}
}

@media screen and(max-width: 720px) {
	#aboutuslist {
		display: none;
	}
}

@media screen and(max-width: 720px) {
	.dratit {
		padding-right: 5%;
		text-align: center;
		font-size: 26px;
		font-weight: bold;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-shadow: 1px 3px 10px #e0e0e0;
		border-radius: 5px;
		
		img {
			width: 100px;
		}
	}
	
	#drawer_header {
		width: 100%;
		margin-top: 5%;
		height: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #eaeaea;
		
		#drawer_header_box {
			width: 25%;
			height: 100%;
			line-height: 30px;
			text-align: center;
			font-weight: bold;
		}
	}
	
	#drawer_main {
		width: 90%;
		margin-left: 5%;
		
		p {
			font-size: 18px;
			margin-top: 1rem;
			font-weight: bold;
		}
		
		#drawer_main_box {
			width: 90%;
			margin-left: 5%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			
			#drawer_main_box_flex {
				width: 50%;
				height: 60px;
				text-align: center;
				line-height: 60px;
				// box-shadow: 6px 5px 10px #d0cece;
				// border-radius: 5px;
			}
		}
	}
}

@media screen and (max-width: 720px) {
	#flbox {
		width: 40px;
		height: 80px;
		background-color: #f1f1f1;
		cursor: pointer;
		position: fixed;
		right: 10px;
		top: 70vh;
		z-index: 99999;
		text-align: center;
		border-radius: 5px;
		
		img {
			width: 40px;
			height: 40px;
		}
		
		p {
			font-size: 14px;
			// font-weight: bold;
			
		}
	}
	
	#flboxleft {
		width: 40px;
		height: 80px;
		background-color: #f1f1f1;
		cursor: pointer;
		position: fixed;
		left: 10px;
		top: 70vh;
		z-index: 99999;
		text-align: center;
		border-radius: 5px;
		
		img {
			width: 40px;
			height: 40px;
		}
		
		p {
			font-size: 14px;
			// font-weight: bold;
			
		}
	}
}

@media screen and (min-width: 720px) {
	#flbox {
		width: 90px;
		height: 120px;
		background-color: #dcdcdc;
		cursor: pointer;
		position: fixed;
		right: 10px;
		top: 80vh;
		z-index: 99999;
		text-align: center;
		border-radius: 5px;
		
		img {
			width: 90px;
			height: 90px;
		}
		
		p {
			font-size: 18px;
			font-weight: bold;
			
		}
	}
	
	#flboxleft {
		width: 90px;
		height: 120px;
		background-color: #dcdcdc;
		cursor: pointer;
		position: fixed;
		left: 10px;
		top: 80vh;
		z-index: 99999;
		text-align: center;
		border-radius: 5px;
		
		img {
			width: 90px;
			height: 90px;
		}
		
		p {
			font-size: 18px;
			font-weight: bold;
			
		}
	}
}

::-webkit-scrollbar {
	width: 0;
	height: 10px
}
</style>
